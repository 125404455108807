@font-face {
  font-family: Aloja;
  src: url(Aloja-Light.woff);
}
@font-face {
  font-family: Ups;
  src:url(upsdowns.ttf)
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans:wght@700&display=swap');

.background{
    background:black;
    width:80vw;
    padding:10vw;
    height:auto;
    position:absolute;
    display:grid;
    place-items:center;
    top:0px;
    left:0vw;
    object-fit: contain;
    z-index:-1;
  }
  
  .title{
    color:white;
    letter-spacing:15px !important;
    user-select:none;
    font-family: "Aloja" !important;
    align-self:flex-end;
    padding-bottom:20px;
  }
  .home_image{
    scale:0.9;
    opacity:0.8;
    place-self: center;
    transition:linear 200ms;
    cursor:pointer;
  }
  .home_image:hover{
    scale:1;
    opacity:1;
    
  }

  .menu{
    position:absolute;
    width:40vw;
    z-index:5;
    height:10vh;
    right:0px;
    top:-10px;
    display:grid;
    grid-template-columns:2fr 2fr 3fr 3fr;
    place-items:center;
  }
  .menu_items{
    color:white;
    text-transform: uppercase;
    cursor:pointer;
    font-family:Ups;
    letter-spacing:2px;
    font-size:1rem;
    user-select:none;
    text-shadow:2px 2px black;
  
  }
  .menu_items:hover{
    color:yellow !important;
  }
  .menu_items:focus{
    color:yellow;
  }

.shop{
  width:100vw;
  height:100%;
  display:grid;
  place-items:center;
}
.shop>section{
  width:60%;
  height:45vh;
  display:grid;
  place-items:center;
  grid-template-columns: 40% 60% ;
  border:2px white solid;
  border-radius:5px;
  transition:linear 200ms;
}
.shop>section:hover{
  scale:1.2;
  border-color:yellow;
  cursor:pointer;
}
.shop>section:hover>div>h2{
  color:yellow;
}
.shop>section>div{
  width:100%;
  display:grid;
  place-items:center;
}
.shop>section>img{
  width:20vw;
  height:20vw;
}
.shop>section>div>p{
  font-family:Montserrat;
}

.lighter_title{
  letter-spacing:15px;
  font-family:Aloja;
  font-weight:300;
  width:clamp(40%,70%,90%);
  text-align: center;
  line-height: 1.2;
  font-size:2.5rem;
  color:white;
}

.gallery_image{
  scale:0.8;
  opacity:0.8;
  transition:linear 200ms;
}
.gallery_image:hover{
  scale:1.2;
  opacity:1;
  z-index:2;

}


.contact{
  color:white;
  height:20vh;
  width:40vw;
  grid-template-columns: 1fr 1fr;
  display:grid;
  place-items:center;
  text-decoration: none !important;
}

.contact_icon{
  width:10vw;
  height:auto;
  filter:invert(1);
  
  transition:ease-in 200ms;
}

.contact_icon:hover{
  scale:2;
  cursor:pointer;
}

.product{
  width:100%;
  height:100%;
  display:grid;
  place-items:center;
}
.back{
  position:absolute;
  top:10vh;
  width:5vw;
  left:5vw;
  filter:invert(1);
  transition:linear 200ms;
  cursor:pointer;
}
.back:hover{
  scale:1.1;
}
.product_title{
  width:90%;
  text-align:center;
  position:absolute;
  top:85vw;
  color:white;
  text-shadow:5px 5px black;
  font-size:4rem;
  user-select:none;
}
.subtitle{
  font-family:Ups;
  color:white;
  font-size:2rem;
  letter-spacing: 1px;
  position:absolute;
  top:92vw;
}
.buy{
  font-family: Montserrat;
  color:white;
  position:absolute;
  top:97vw;
  scale:1;
  background:rgba(0,255,0,0.3);
  padding:10px;
  border-radius:5px;
  font-size:2rem;
  user-select:none;
  transition:linear 200ms;
  text-transform:uppercase;
  cursor:pointer;
  text-shadow:2px 2px black;
  box-shadow: 2px 2px black;
}
.buy:hover{
  scale:1;
  background:rgba(0,255,0,0.5);
  
}
.buy:active{
  background:darkgreen;
  box-shadow:none;
  scale:0.95;
}
.product_featured{
  top:0px;
  z-index:-1;
  left:0px;
  width:100vw;
  height:auto;
  opacity:1;
  filter:brightness(0.7);
  position:absolute;
}
.fragility{
  display:grid;
  grid-template-rows: 2fr 3fr 2fr;
  color:white;
  place-items:center;
  background:#000d;
  width:25vw;
  border-radius:25px;
}
p.fragility{
  letter-spacing: 5px;
}
.frag{
  font-size:2.5rem;
}
.warn{
  word-wrap:yes;
  width:20vw;
  text-align:center;
  align-self:flex-start;
}
.details{
  position:absolute;
  top:110vw;
  width:100vw;
  height:80vw;
  display:grid;
  place-items:center;
  grid-template-rows:10vh 60vh 90vh;
  font-family:Montserrat;
  color:white;
}
.details_text{
  font-family:Ups;
  font-size:2rem;
  text-shadow:2px 2px black;
}
.product_mirror{
  position:absolute;
  top:99vw;
  filter:brightness(0.7) blur(5px);
  width:100vw;
  transform:rotateX(180deg);
}


.footer{
  z-index:5;
  position:fixed;
  width:50vw;
  background:none;
  height:10vh;
  color:#fff;
  bottom:0px;
  left:25vw;
  opacity:0.7;
  display:grid;
  grid-template-rows: 100%;
  place-items:center;
  overflow:hidden;
  transition:linear 200ms;
}
.footer > h2{
  font-family: Montserrat;
}
.footer > div{
  display:grid;
  width:30vw;
  place-items:center;
  grid-template-columns: 13vw 4vw 13vw;
}
.footer > div > p > a{
  color:white;
  font-family:Ups;
  text-decoration: none;
  transition:200ms linear;
}
.footer > div > p:nth-child(2){
  font-size:1.6rem;
}
.footer > div > p > a:hover{
  color:yellow;
}
.footer:hover{
  opacity:1;
}
@media(orientation:portrait) and (max-width:600px){
  .menu{
    width:100vw;
    top:-20px;
    place-items:center;
  }
  .menu_items{
    font-size:1rem;
    
    
  }
  .footer{
    height:5vh;
    font-size:0.5rem !important;
  }
  .footer > div > p:nth-child(2){
    font-size:1rem;
  }
  .title{
    font-size:2.5rem !important;
    padding-bottom:10px;
  }
  .home_image{
    width:50vw !important;
    
    padding:0px 5vw;
  }
  .home_image_container:first-of-type{
    padding-left:20vw;
  }
  .home_image_container:last-of-type{
    padding-right:20vw;
  }
  

  .shop{
    width:100vw;
    height:100%;
  }
  .shop>section{
    width:70%;
    height:45vh;
    grid-template-columns: 100% ;
    grid-template-rows: 60% 40%;
    border-radius:25px;
  }
  .lighter_title{
    font-size:1.5rem !important;
    letter-spacing:5px;
    line-height: 2;
  }
  .shop>section>div{
    width:100%;
    grid-template-rows: 50% 50%;
  }
  .shop>section>img{
    width:40vw;
    height:auto;
  }

  .gallery_container{
    width:90vw !important;
  }
  .gallery_image{
    scale:1;
    width:30vw !important;
    opacity:0.8;
    transition:linear 200ms;
  }
  .gallery_image:hover{
    scale:1.2;
    opacity:1;
    z-index:2;

  }

  .contact{
    width:60vw;
  }
  .contact_icon{
    width:20vw;
    
  }


  .product_featured{
    z-index:-1;
    width:100vw;
    height:100vh;
    object-fit: cover;
  }
  .product_mirror{
    top:99vh;
    left:0px;
    width:100vw;
    height:120vh;
    object-fit: cover;
  }
  .back{
    width:10vw;
  }
  .product_title{
    top:85vh;
    font-size:clamp(1.5rem, 2rem, 2.5rem) !important;
    
  }
  .subtitle{
    font-family:Ups;
    color:white;
    font-size:1.2rem;
    letter-spacing: 1px;
    top:100vh;
  }
  .buy{
    position:absolute;
    top:105vh;
  }
  .details{
    position:absolute;
    top:115vh;
    width:100vw;
    height:100vh;
    grid-template-rows:10vh 30vh 70vh;
  }
  .details_text{
    font-family:Ups;
    font-size:2rem;
    text-shadow:2px 2px black;
  }
  .fragility{
    display:grid;
    grid-template-rows: 2fr 1fr 3fr;
    color:white;
    place-items:center;
    height:30vh;
    width:60vw;
  }
  .frag{
    font-size:1rem;
  }
  .warn{
    font-size:1rem;
    width:50vw;
  }
  p.fragility{
    letter-spacing: 5px;
  }
  
}