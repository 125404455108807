
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s ease-out;
  }
}

.App{
  width:100vw;
  height:100vh;
  overflow:hidden;
  overflow-x:hidden;
  display:grid;
  z-index:-2;
  background:black;
}
.App > section{
  width:100vw;
  height:100vh;
  display:grid;
  place-items:center;
}

#home{
  grid-template-rows:40vh 60vh;
}
#shop{
  height:380vh;
  display:none;
  overflow-y:auto;
  overflow-x:hidden;
  grid-template-rows:40vh 320vh;
}
#gallery{
  height:480vh;
  display:none;
  overflow-y:auto;
  overflow-x:hidden;
  grid-template-rows:40vh 430vh;
}
#contact{
  grid-template-rows:40vh 40vh;
  display:none;
}
#product{
  height:100vw;
  overflow-y:auto;
  display:none;
  grid-template-rows:30vh 110vh;
  z-index:0;
}

@media(orientation:portrait) and (max-width:600px){
  
  #home{
    grid-template-rows:25vh 75vh;

  }

  #shop{
    height:350vh;
    grid-template-rows:25vh 320vh;
  }

  #gallery{
    height:455vh;
    grid-template-rows:25vh 430vh;
  }


  #product{
    height:100vw;
    overflow-y:auto;
    display:none;
    grid-template-rows:30vh 110vh;
    z-index:0;
  }
}